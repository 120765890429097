import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ILanguageSpecificStringWithType } from '@theia-cc/shared/models';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class InputComponent implements OnChanges, AfterViewInit {
  @Input() public showLock: boolean;
  @Input() public validationError: boolean;
  @Input() public inputDisabled = false;
  @Input() public inputLabel: string;
  @Input() public helpText: string;
  @Input() public readonly: boolean;
  @Input() public inputName: string;
  @Input() public inputType = 'text';
  @Input() public inputValue: any;
  @Input() public shouldShowValidation: boolean;
  @Input() public inputId;
  @Input() public maxLength;
  @Input() public isRequired = true;
  @Input() public autofocus = false;
  @Input() public isLabelTranslatedViaCms = false;
  @Input() public inputLabelCms: ILanguageSpecificStringWithType;

  @Output() public inputValueChange = new EventEmitter<string>();
  @Output() public inputEnter = new EventEmitter<string>();
  @Output() public inputKeyDown = new EventEmitter<any>();
  @Output() public onInputBlur = new EventEmitter<any>();

  @ViewChild('inputRef', { static: true }) inputRef: ElementRef<HTMLInputElement>;

  public inputTouched = false;

  constructor(private translate: TranslateService) {}

  reportValidity() {
    const input = this.inputRef?.nativeElement;
    const validityState = input.validity;
    input?.setCustomValidity(
      validityState?.valueMissing ? this.translate.instant('CONTACT_FORM.ERROR.REQUIRED') : ''
    );
    input?.reportValidity();
  }

  ngAfterViewInit() {
    if (this.autofocus) {
      setTimeout(() => {
        this.inputRef?.nativeElement?.focus();
      }, 300);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.inputValue) {
      this.inputTouched =
        changes.inputValue.currentValue !== '' &&
        changes.inputValue.currentValue !== null &&
        changes.inputValue.currentValue !== undefined;
      this.inputValueChange.emit(changes.inputValue.currentValue);
    }
  }

  inputFocus() {
    this.inputTouched = true;
  }

  inputBlur(value) {
    if (!value || value === '') {
      this.inputTouched = false;
    }
    this.onInputBlur.emit(value);
  }
}
